<template>
  <div class="row mt-5" v-if="isAdmin || isFinanzas || isContratista">
    <!-- ADELANTO -->
    <div class="col-12 col-md-6" v-if="asignacion.AdelantoContratista">
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold mr-2" v-b-toggle:adelanto-collapse>
            ADELANTO SOLICITADO
          </p>
          <b-collapse id="adelanto-collapse">
            <hr />

            <div v-if="asignacion.AdelantoContratista">
              <p class="h6 card-subtitle my-3">
                Estado del Adelanto:
                <span
                  class="badge badge-pill p-2"
                  :class="
                    asignacion.AdelantoContratista.estado == 1
                      ? 'badge-success'
                      : 'badge-dark'
                  "
                  >{{
                    asignacion.AdelantoContratista.estado == 1
                      ? "APROBADO"
                      : "PENDIENTE DE REVISIÓN"
                  }}</span
                >
              </p>
              <hr />
              <p class="h6 card-subtitle my-3">
                Fecha de Solicitud:
                <code>{{ asignacion.AdelantoContratista.fecha }}</code>
              </p>
              <hr />
              <p class="h6 card-subtitle my-3">
                Monto Solicitado:
                <code>{{
                  formatoAPrecio(asignacion.AdelantoContratista.monto)
                }}</code>
              </p>
            </div>
            <div v-else>
              <p class="h6">
                No se ha solicitado un adelanto por parte del contratista.
              </p>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>

    <!-- PARTE BONO -->
    <div class="col-12 col-md-6 mt-3 mt-md-0" v-if="bonoVista()">
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold mr-2" v-b-toggle:bono-collapse>
            BONO
          </p>
          <b-collapse id="bono-collapse">
            <hr />

            <div v-if="asignacion.bono && (isAdmin || isFinanzas)">
              <p>
                Monto abonado:
                <code>{{ formatoAPrecio(asignacion.bono) }}</code>
              </p>
            </div>
            <div v-else>
              <b-button
                v-if="
                  asignacion.estado >= 7 && asignacion.estado < 13 && isAdmin
                "
                variant="outline-primary"
                @click="openEmitBono"
                ><i class="fas fa-plus mr-2"></i>Añadir Bono</b-button
              >
              <p v-else class="h6">
                No se puede dar un bono en el estado presente de la asignación
              </p>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { precio } from "@/mixins/precio";
export default {
  props: { asignacion: { type: Object, required: true } },
  mixins: [precio],
  methods: {
    openEmitBono() {
      this.$emit("open-bono");
    },
    bonoVista() {
      if (this.isAdmin) {
        return this.asignacion.estado >= 7;
      }
      if (this.isFinanzas) {
        return this.asignacion.estado >= 7 && this.asignacion.bono;
      }
    },
  },

  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    isAdmin() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    isSupervisor() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    isContratista() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 3;
    },
    isFinanzas() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 4;
    },
  },
};
</script>
