<template>
     <div class="row mt-5">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold mr-2" v-b-toggle:description-collapse>
                DESCRIPCIÓN
              </p>
              <b-collapse  id="description-collapse">
                <hr />
                <code>{{ asignacion.descripcionServicio }}</code>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  props: { asignacion: { type: Object, required: true } },
};
</script>
