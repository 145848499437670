<template>
  <!-- FOTO DE VISITA -->

  <div class="">
    <div class="col-12 card">
      <div class="card-body">
        <h6 class="card-title" v-b-toggle:foto-visita>
          <b>Foto de Visita </b>
          {{
            asignacion.fotoFichaVisita != null &&
            asignacion.fotoFichaVisita.nombre
              ? ` - ${asignacion.fotoFichaVisita.nombre}`
              : ""
          }}
        </h6>
        <b-collapse  id="foto-visita">
          <hr />
          <img
            v-if="asignacion.fotoFichaVisita.id"
            class="img-fluid"
            :src="asignacion.fotoFichaVisita.url"
            :alt="asignacion.fotoFichaVisita.nombre"
          />
          <p class="text-center h6 font-weight-bold font-italic" v-else>
            El supervisor asignado aún no ha registrado visita a local.
          </p>
        </b-collapse>
      </div>
    </div>

    <!-- FOTOS INICIALES -->
    <div class="col-12 card mt-5">
      <div class="card-body">
        <h6 class="card-title" v-b-toggle:fotos-iniciales>
          <b>Fotos Iniciales</b>
        </h6>
        <b-collapse  id="fotos-iniciales">
          <b-tabs v-if="asignacion.fotosInicio.length > 0" content-class="mt-3">
            <b-tab title="Galería" active>
              <Galeria
                @send-image="openImage($event)"
                :fotos="asignacion.fotosInicio"
              />
            </b-tab>
            <b-tab title="Muro"
              ><Masonry
                @send-image="openImage($event)"
                :fotos="asignacion.fotosInicio"
                :idGallery="1"
            /></b-tab>
          </b-tabs>
          <p class="h6 font-weight-bold" v-else>
            Aún no se ha agregado fotos iniciales
          </p>
        </b-collapse>
      </div>
    </div>

    <!-- FOTOS FINALES -->
    <div class="col-12 card mt-5 ">
      <div class="card-body">
        <h6 class="card-title" v-b-toggle:fotos-finales>
          <b>Fotos Finales </b>
        </h6>
        <b-collapse  id="fotos-finales">
          <b-tabs v-if="asignacion.fotosFin.length > 0" content-class="mt-3">
            <b-tab title="Galería" active>
              <Galeria
                @send-image="openImage($event)"
                :fotos="asignacion.fotosFin"
              />
            </b-tab>
            <b-tab title="Muro"
              ><Masonry2
                @send-image="openImage($event)"
                :fotos="asignacion.fotosFin"
                :idGallery="2"
            /></b-tab>
          </b-tabs>
          <p class="h6 font-weight-bold" v-else>
            Aún no se ha agregado fotos finales
          </p>
        </b-collapse>
      </div>
    </div>

    <b-modal
      v-if="imageSelected"
      size="xl"
      hide-header
      centered
      hide-footer
      id="modal-imagen"
      title="BootstrapVue"
    >
      <img class="image-wrapped" :src="imageSelected.url" alt="" />
    </b-modal>
  </div>
</template>

<script>
import Galeria from "./fotos/galeria.vue";
import Masonry from "./fotos/masonry.vue";
import Masonry2 from "./fotos/masonry2.vue";
import Carousel from "./fotos/carrusel.vue";

export default {
  props: { asignacion: { type: Object, required: true } },
  components: { Galeria, Masonry, Masonry2, Carousel },
  data() {
    return {
      imageSelected: null,
    };
  },
  methods: {
    openImage(image) {
      this.imageSelected = image;
      this.$bvModal.show("modal-imagen");
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapped {
  width: 100%;
  height: 80%;
  max-height: 80vh;
  object-fit: contain;
}
.foto {
  width: 100%;
}

@media (min-width: 1440px) {
  .row {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}
</style>
