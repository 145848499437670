<template>
  <div class="row mt-5">
    <!-- TIEMPO DE ENTREGA -->
    <div
      class="col-12 col-sm-6 col-md-4 col-lg-3"
      v-if="asignacion.tiempoEntrega"
    >
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold mr-2 text-uppercase">
            Tiempo de Entrega
          </p>
          <hr />

          <p class="h5">{{ asignacion.tiempoEntrega }} días</p>
        </div>
      </div>
    </div>
    <!-- TIEMPO OFERTA VALIDA -->
    <div
      class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mt-sm-2 mt-lg-0"
      v-if="asignacion.ofertaValida"
    >
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold mr-2 text-uppercase">Oferta Válida</p>
          <hr />

          <p class="h5">{{ asignacion.ofertaValida }} días</p>
        </div>
      </div>
    </div>
    <!-- COSTO ADICIONAL -->
    <div
      class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mt-sm-0 mt-lg-0"
      v-if="isAdmin || isFinanzas"
    >
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold mr-2 text-uppercase">
            Costo Adicional
          </p>
          <hr />

          <p class="h5">{{ formatoAPrecio(asignacion.costoAdicional) }}</p>
        </div>
      </div>
    </div>
    <!-- FECHA DE INICIO VIGENTE -->
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mt-sm-2 mt-lg-0">
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold mr-2 text-uppercase">
            Fecha de Inicio Vigente
          </p>
          <hr />

          <p v-if="asignacion.fechaInicioVigente" class="h5">
            {{ asignacion.fechaInicioVigente }}
          </p>
          <p v-else>Aún no se ha aprobado enteramente las partidas.</p>
        </div>
      </div>
    </div>
    <!-- MONTO TOTAL ADMINISTRADOR -->
    <div
      class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mt-sm-2 mt-lg-0"
      v-if="(isAdmin || isFinanzas) && asignacion.totalPrecioAdministrador"
    >
      <div class="card">
        <div class="card-body bg-info text-white">
          <p class="font-weight-bold mr-2 text-uppercase">
            Precio Total Administrador
          </p>
          <hr />
          <p class="h5">
            {{ formatoAPrecio(asignacion.totalPrecioAdministrador) }}
          </p>
        </div>
      </div>
    </div>
    <!-- MONTO TOTAL CONTRATISTA -->
    <div
      class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mt-sm-2 mt-lg-0"
      v-if="
        (isAdmin || isFinanzas || isContratista) &&
          asignacion.totalPrecioContratista
      "
    >
      <div class="card">
        <div class="card-body bg-dark text-white">
          <p class="font-weight-bold mr-2 text-uppercase">
            Precio Total Contratista
          </p>
          <hr />
          <p class="h5">
            {{ formatoAPrecio(asignacion.totalPrecioContratista) }}
          </p>
        </div>
      </div>
    </div>
    <!-- CUENTA CONTRATISTA -->
    <div
      class="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 mt-sm-2 "
      v-if="isAdmin || isFinanzas"
    >
      <div class="card">
        <div class="card-body bg-warning">
          <p class="font-weight-bold mr-2 text-uppercase">
            Datos de Contratista
          </p>
          <hr />

          <p class="h6">
            <span class="font-weight-bold text-uppercase">Nombre: </span>
            {{ asignacion.contratista.nombres }}
          </p>
          <p class="h6">
            <span class="font-weight-bold text-uppercase">
              Apellido:
            </span>
            {{ asignacion.contratista.apellidos }}
          </p>

          <p class="h6">
            <span class="font-weight-bold text-uppercase">Banco: </span>
            {{ asignacion.contratista.banco }}
          </p>
          <p class="h6">
            <span class="font-weight-bold text-uppercase">
              Número de Cuenta:
            </span>
            {{ asignacion.contratista.nroCuenta }}
          </p>
          <p class="h6">
            <span class="font-weight-bold text-uppercase">CCI: </span>
            {{ asignacion.contratista.cci }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { precio } from "@/mixins/precio";
export default {
  props: { asignacion: { type: Object, required: true } },
  mixins: [precio],
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    isAdmin() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    isSupervisor() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    isContratista() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 3;
    },
    isFinanzas() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 4;
    },
  },
};
</script>
