<template>
  <div class="d-flex justify-content-center align-items-center">
    <img
      :src="asignacion.local.imagen"
      :alt="asignacion.local.nomLocal"
      class="detalles-asignacion__local-image img-fluid"
    />
  </div>
</template>

<script>
export default {
  props: { asignacion: { type: Object, required: true } },
};
</script>
