<template>
  <section class="carousel">
    <b-carousel
      :id="'carousel-' + idGallery"
      v-model="slide"
      :interval="4000"
      controls
      fade
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="foto of fotos"
        :key="foto.id + '-' + foto.nombre + '-' + Math.random()"
        :text="foto.nombre"
        :img-src="foto.url"
      ></b-carousel-slide>
         
    </b-carousel>
  </section>
</template>

<script>
export default {
  props: {
    fotos: { type: Array, required: true },
    idGallery: { type: Number, required: true },
  },
  data() {
    return {
      slide: 0,
    };
  },
};
</script>
