<template>
  <div class="row justify-content-center mt-5 card p-3 pb-5">
    <!-- Solo admin -->
    <div class="col-md-12 row card-body ">
      <p class="h6 font-italic detalles-asignacion__sub font-weight-bold my-0">
        Personal asignado
      </p>
      <hr />
    </div>

    <div class="col-md-12 ">
      <div class="grid-personal">
        <!-- SUPERVISOR -->
        <div class="personal">
          <div class="row card">
            <div class="card-body d-flex flex-column">
              <span class="text-muted mb-2">Supervisor:</span>

              <div
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <img
                  :src="asignacion.supervisor.foto"
                  :alt="asignacion.supervisor.nombres"
                  class="foto"
                />

                <span class="text-muted d-inline-block mt-2 h5">
                  {{
                    `${asignacion.supervisor.nombres} ${asignacion.supervisor.apellidos}`
                  }}
                </span>
              </div>

              <div class="row mt-3 small">
                <div class="col-12">
                  <span>
                    <i class="fas fa-mobile-alt mr-1"></i>
                    {{ asignacion.supervisor.celular }}</span
                  >
                </div>
                <div class="col-12">
                  <span
                    ><i class="far fa-envelope mr-1"></i>
                    {{ asignacion.supervisor.email }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- CONTRATISTA -->
        <div class="personal">
          <div class="card">
            <div class="card-body d-flex flex-column">
              <span class="text-muted mb-2">Contratista:</span>

              <div
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <img
                  :src="asignacion.contratista.foto"
                  :alt="asignacion.contratista.nombres"
                  class=" foto"
                />

                <span class="text-muted d-inline-block mt-2 h5">
                  {{
                    `${asignacion.contratista.nombres} ${asignacion.contratista.apellidos}`
                  }}
                </span>
              </div>

              <div class="row  mt-3 small">
                <div class="col-12 ">
                  <span>
                    <i class="fas fa-mobile-alt mr-1"></i>
                    {{ asignacion.contratista.celular }}</span
                  >
                </div>
                <div class="col-12">
                  <span
                    ><i class="far fa-envelope mr-1"></i>
                    {{ asignacion.contratista.email }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Fianza -->
        <div class="personal">
          <div class="card">
            <div class="card-body d-flex flex-column">
              <span class="text-muted mb-2">Finanzas:</span>

              <div
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <img
                  :src="asignacion.fianza.foto"
                  :alt="asignacion.fianza.nombres"
                  class=" foto"
                />

                <span class="text-muted d-inline-block mt-2 h5">
                  {{
                    `${asignacion.fianza.nombres} ${asignacion.fianza.apellidos}`
                  }}
                </span>
              </div>

              <div class="row  mt-3 small">
                <div class="col-12 ">
                  <span>
                    <i class="fas fa-mobile-alt mr-1"></i>
                    {{ asignacion.fianza.celular }}</span
                  >
                </div>
                <div class="col-12">
                  <span
                    ><i class="far fa-envelope mr-1"></i>
                    {{ asignacion.fianza.email }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { asignacion: { type: Object, required: true } },
};
</script>

<style lang="scss" scoped>
.grid-personal {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  .personal {
    /* aspect-ratio: 1; */
    height: 100%;
    .card {
      height: 100%;
      padding: 10px 0 30px 0;
      .foto {
        width: 80%;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .grid-personal {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1140px) {
  .grid-personal {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
