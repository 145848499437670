<template>
  <!-- En caso de tener TAREAS -->
  <!-- MUESTRA DE TAREAS ASIGNADAS -->
  <div class="row">
    <div class="col-md-12 mb-3" v-for="tarea in tareas" :key="tarea.tareaId">
      <!-- Muestra un card con las tareas, cada card es collapse y muestra info detallada de cada tarea -->
      <b-card class="bg-info">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <a
              class="text-light"
              href=""
              v-b-toggle="tarea.tareaId"
              @click.prevent
              variant="info"
              >{{ tarea.descripcion }}</a
            >
          </div>
        </div>

        <b-collapse  :id="tarea.tareaId" class="mt-2">
          <hr />

          <b-card>
            <p class="mb-3">
              <i class="fas fa-calendar-alt"></i>
              {{ tarea.created_at | moment("DD/MM/YYYY HH:MM:SS") }}
            </p>

            <p class="my-3">
              <span
                class="badge-pill p-2"
                :class="`badge-${getEstado(tarea)[2]}`"
              >
                Estado: <b>{{ getEstado(tarea)[0] }}</b>
              </span>
            </p>

            <small class="text-muted mt-3">Observaciones:</small>

            <p class="card-text my- pb-1 border-bottom">
              {{ tarea.observacion }}
            </p>

            <div class="row mt-2">
              <div class="col-md-12">
                <p class="my-1 pb-1 border-bottom">
                  <span class="small text-muted">Descripción</span>
                  <span class="ml-2">
                    {{ tarea.descripcion }}
                  </span>
                </p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-md-6">
                <p class="my-1 pb-1 border-bottom">
                  <span class="small text-muted">Horario asignado:</span>
                  <span class="ml-2">{{
                    tarea.horario === 0 ? "Diurno" : "Nocturno"
                  }}</span>
                </p>
              </div>
              <div class="col-12 col-md-6">
                <p class="mt-3 mb-1 pb-1 border-bottom">
                  <span class="small text-muted">Medidas:</span>
                  <span class="ml-2"
                    >{{ tarea.cantidad }} ({{ tarea.unidadMedida }})</span
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="isAdmin || isFinanzas">
                <div class="my-1 pb-1 border-bottom">
                  <span class="small text-muted">Precio administrador:</span>

                  <br />

                  <div class="contenedor-precio">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span>{{
                          formatoAPrecio(tarea.precioAdministrador)
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <span class="small font-weight-bold"
                    >Subtotal:
                    {{
                      formatoAPrecio(
                        setSubtotal(tarea.precioAdministrador, tarea.cantidad)
                      )
                    }}</span
                  >
                </div>
              </div>

              <!-- PRECIO CONTRATISTA -->
              <div class="col-12 col-md-6" v-if="isAdmin || isFinanzas || isContratista">
                <div class="my-1 pb-1 border-bottom">
                  <span class="small text-muted">Precio Contratista:</span>

                  <br />

                  <div class="contenedor-precio">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span>{{
                          formatoAPrecio(tarea.precioContratista)
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <span class="small font-weight-bold"
                    >Subtotal:
                    {{
                      formatoAPrecio(
                        setSubtotal(tarea.precioContratista, tarea.cantidad)
                      )
                    }}</span
                  >
                </div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { precio } from "@/mixins/precio";
import regexMixin from "@/mixins/regexMixins";
import { mapState } from "vuex";
import { estadosTareas } from "../../../../.env";
import {
  hideAdmin,
  hideContratante,
  hideSupervisor,
} from "@/directives/roleDirective.js";
export default {
  props: { tareas: { type: Array, required: true } },
  mixins: [precio, regexMixin],
  directives: { hideAdmin, hideContratante, hideSupervisor },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
   
        isAdmin() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    isSupervisor() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    isContratista() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 3;
    },
    isFinanzas() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 4;
    },
  },
  data() {
    return { estados: estadosTareas };
  },
  methods: {
    setSubtotal(precio, cantidad) {
      return parseFloat(precio) * parseInt(cantidad);
    },
    getEstado(item) {
      let estado = this.estados.find((e) => e.value == item.estado);

      return [
        estado["title"],
        estado["value"],
        estado["variant"],
        estado["icon"],
      ];
    },
  },
};
</script>
