<template>
  <section class="gallery-masonry" :id="'masonry-' + idGallery">
    <div
      class="thumbnail-masonry"
      v-for="foto of fotos"
      :key="foto.id + '-' + foto.nombre + '-' + Math.random()"
    >
      <div class="img-container" @click="$emit('send-image', foto)">
        <img :src="foto.url" :alt="foto.nombre" />
        <div class="img-caption table">
          <span class="table-cell">
            <p>{{ foto.nombre }}</p>

          </span
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    fotos: { type: Array, required: true },
    idGallery: { type: Number, required: true },
  },
  mounted() {
    const masonry = document.querySelector(`#masonry-${this.idGallery}`);
    const thumbnail = document.querySelectorAll(".thumbnail-masonry");

    this.launchMasonry(masonry, thumbnail);
  },
  methods: {
    launchMasonry(masonry, thumbnail) {
      if (window.innerWidth < 576) {
        this.masonryLayout(masonry, thumbnail, 1);
      } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
        this.masonryLayout(masonry, thumbnail, 2);
      } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
        this.masonryLayout(masonry, thumbnail, 3);
      } else if (window.innerWidth >= 992 && window.innerWidth < 1140) {
        this.masonryLayout(masonry, thumbnail, 4);
      } else {
        this.masonryLayout(masonry, thumbnail, 5);
      }
    },
    masonryLayout(containerElem, itemsElems, columns) {
      containerElem.classList.add("masonry-layout", `columns-${columns}`);

      let columnsElements = [];

      for (let i = 1; i <= columns; i++) {
        let column = document.createElement("div");
        column.classList.add("masonry-column", `column-${i}`);

        containerElem.appendChild(column);
        columnsElements.push(column);
      }

      for (let m = 0; m < Math.ceil(itemsElems.length / columns); m++) {
        for (let n = 0; n < columns; n++) {
          let item = itemsElems[m * columns + n];
          columnsElements[n].appendChild(item);
          item.classList.add("masonry-item");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$font-family: "Century Gothic", sans-serif;
$font-size-base: 16px;
$font-weight: 100;
$bg-color: #444;
$bg-color2: #666;
$yellow: rgb(249, 229, 89);
$almost-white: rgb(255, 255, 255);
$btn-transparent-color: rgba(249, 229, 89, 1);
$btn-transparent-bg: rgba(249, 229, 89, 0);

.thumbnail-masonry {
  float: left;
  position: relative;
  overflow: hidden;
      cursor: pointer;

  .img-container {
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      display: block;
    }
    &:hover .img-caption {
      top: 0;
      left: 0;
      .btn-trans {
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid $yellow;
      }
    }
  }
}

.img-caption {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
}
.table {
  display: table;
  .table-cell {
    display: table-cell;
    vertical-align: middle;
    border: 2px solid $yellow;

    p {
      color: white;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }
}
.btn {
  border: none;
  font-size: inherit;
  font-weight: $font-weight;
  color: $btn-transparent-color;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  border: 1px solid $yellow;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  &::after {
    line-height: 1;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }
}
.btn-sep {
  margin: 30px 0px;
}
.btn-p {
  font-size: $font-size-base;
  padding: 10px 20px;
}

.btn-trans {
  background: $btn-transparent-bg;
  color: $yellow;
  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
  &:active {
    background: rgba(255, 255, 255, 0.4);
  }
}

.masonry-layout {
  --columns: 2;
  --gap: 20px;
  $columns: 6;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);

  grid-gap: var(--gap);

  @for $i from 1 through $columns {
    &.columns-#{$i} {
      --columns: #{$i};
    }
  }

  .masonry-item {
    margin-bottom: var(--gap);
  }
}

@media screen and (min-width: 576px) {
  .masonry-layout {
    --columns: 2;
  }
}
@media screen and (min-width: 768px) {
  .masonry-layout {
    --columns: 3;
  }
}
@media screen and (min-width: 992px) {
  .masonry-layout {
    --columns: 4;
  }
}
@media screen and (min-width: 1140px) {
  .masonry-layout {
    --columns: 6;
  }
}
</style>
