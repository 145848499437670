<template>
  <section class="gallery">
    <div
      class="thumbnail"
      v-for="foto of fotos"
      :key="foto.id + '-' + foto.nombre + '-' + Math.random()"
    >
      <div class="img-container" @click="$emit('send-image', foto)">
        <img :src="foto.url" :alt="foto.nombre" />
        <div class="img-caption table">
          <span class="table-cell">
            <p >{{ foto.nombre }}</p>

         </span
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { fotos: { type: Array, required: true } },
};
</script>

<style lang="scss" scoped>
$font-family: "Century Gothic", sans-serif;
$font-size-base: 16px;
$font-weight: 100;
$bg-color: #444;
$bg-color2: #666;
$yellow: rgb(249, 229, 89);
$almost-white: rgb(255, 255, 255);
$btn-transparent-color: rgba(249, 229, 89, 1);
$btn-transparent-bg: rgba(249, 229, 89, 0);

.gallery {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 60px;

  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 20px;

  .thumbnail {
    float: left;
    position: relative;
    overflow: hidden;
    aspect-ratio: 1;
    cursor: pointer;

    .img-container {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:hover .img-caption {
        top: 0;
        left: 0;
        .btn-trans {
          background: rgba(255, 255, 255, 0.4);
          border: 1px solid $yellow;
        }
      }
    }
  }

  .img-caption {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
  }
  .table {
    display: table;
    .table-cell {
      display: table-cell;
      vertical-align: middle;
      border: 2px solid $yellow;

      p {
        color: white;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
    }
  }
  .btn {
    border: none;
    font-size: inherit;
    font-weight: $font-weight;
    color: $btn-transparent-color;
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    border: 1px solid $yellow;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    &::after {
      line-height: 1;
      position: relative;
      -webkit-font-smoothing: antialiased;
    }
  }
  .btn-sep {
    margin: 30px 0px;
  }
  .btn-p {
    font-size: $font-size-base;
    padding: 10px 20px;
  }

  .btn-trans {
    background: $btn-transparent-bg;
    color: $yellow;
    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
    &:active {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

@media screen and (min-width: 576px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
@media screen and (min-width: 768px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}
@media screen and (min-width: 992px) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
}
@media screen and (min-width: 1140px) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
}
</style>
