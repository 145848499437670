<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="timeline py-3">
            <div
              class="timeline-block"
              v-for="tarea of tareas"
              :key="tarea.tareaId"
            >
              <span
                class="timeline-step success"
                :class="`badge-${getEstado(tarea)[2]}`"
              >
                <i :class="getEstado(tarea)[3]"></i>
              </span>

              <div class="timeline-content">
                <small class="text-muted font-weight-bold">
                  {{ tarea.updated_at | moment("DD/MM/YYYY HH:MM:SS") }}</small
                >
                <h6 class="mt-3 mb-0">
                  {{ tarea.descripcion.toUpperCase() }}
                </h6>
                <p class="mt-1 mb-0">{{ tarea.observacion }}</p>
                <p class="text-sm mt-1 mb-0 font-weight-bold">
                  Cantidad: {{ tarea.cantidad }}({{ tarea.unidadMedida }})
                </p>

                <!-- PRECIOS ADMINISTRADOR -->
                <div class="class mt-3 d-flex flex-wrap" v-if="isAdmin || isFinanzas">
                  <b-button size="sm" variant="primary" 
                    ><i class="fas fa-user-tie"></i> Costo Administrador :
                    {{ formatoAPrecio(tarea.precioAdministrador) }}</b-button
                  >
                  <b-button size="sm" variant="primary" class="mt-3 mt-sm-0 ml-0 ml-sm-2" 
                    ><i class="fas fa-user-tie"></i>Subtotal Administrador :
                    {{
                      formatoAPrecio(
                        setSubtotal(tarea.precioAdministrador, tarea.cantidad)
                      )
                    }}
                  </b-button>
                </div>

                <!-- PRECIO CONTRATISTA -->
                <div class="class mt-1 d-flex flex-wrap" v-if="isAdmin || isFinanzas || isContratista">
                  <b-button size="sm" variant="success"
                    ><i class="fas fa-user-cog"></i> Costo Contratista :
                    {{ formatoAPrecio(tarea.precioContratista) }}
                  </b-button>
                  <b-button size="sm" variant="success" class="mt-3 mt-sm-0 ml-0 ml-sm-2"
                    ><i class="fas fa-user-cog"></i>Subtotal Contratista :
                    {{
                      formatoAPrecio(
                        setSubtotal(tarea.precioContratista, tarea.cantidad)
                      )
                    }}
                  </b-button>
                </div>

                <div class="mt-3">
                  <span
                    size=""
                    class="badge badge-pill p-2"
                    :class="`badge-${getEstado(tarea)[2]}`"
                    >ESTADO: {{ getEstado(tarea)[0] }}</span
                  >&nbsp;
                  <span
                    size=""
                    class="badge badge-pill p-2"
                    :class="[
                      tarea.horario == 0 ? 'badge-primary' : 'badge-dark',
                    ]"
                    >Horario:
                    {{ tarea.horario == 0 ? "DIURNO" : "NOCTURNO" }}</span
                  >&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { precio } from "@/mixins/precio";
import { mapState } from "vuex";
import { estadosTareas } from "../../../../.env";

import {
  hideAdmin,
  hideContratante,
  hideSupervisor,
} from "@/directives/roleDirective.js";
export default {
  props: {
    tareas: { type: Array, required: true },
  },
  mixins: [precio],
  directives: { hideAdmin, hideContratante, hideSupervisor },
  data() {
    return {
      estados: estadosTareas,
    };
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  
        isAdmin() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    isSupervisor() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    isContratista() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 3;
    },
    isFinanzas() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 4;
    },
  },
  methods: {
    setSubtotal(precio, cantidad) {
      return parseFloat(precio) * parseInt(cantidad);
    },
    getEstado(item) {
      let estado = this.estados.find((e) => e.value == item.estado);

      return [
        estado["title"],
        estado["value"],
        estado["variant"],
        estado["icon"],
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.text-sm {
  font-size: 0.875rem !important;
}

.timeline {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    border-right: 2px solid #e9ecef;
  }

  &-block {
    &:first-child {
      margin-top: 0;
    }
    position: relative;
    margin: 2em 0;
  }

  &-step {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    text-align: center;
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: 600;
    z-index: 1;
  }

  &-content {
    width: auto;
    margin-left: 40px;
    padding-top: 0.5rem;
    position: relative;
    top: -6px;
  }
}

.timeline-one-side .timeline-step,
.timeline-one-side:before {
  left: 1rem;
}
</style>
