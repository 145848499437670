<template>
  <LayoutView
    :titulo="`Detalle de asignación #${id}`"
    :fondo="asignacion && asignacion ? asignacion.local.imagen : ''"
    :seccionDetalle="
      `Detalle de la Asignación: [${
        asignacion ? asignacion.local.nomLocal : ''
      } - ${id}]`
    "
  >
    <section v-if="asignacion">
      <!-- IMAGEN DEL NEGOCIO LOCAL -->
      <ImagenNegocio :asignacion="asignacion" />

      <!-- DESCRIPCION DEL SERVICIO ESCRITO POR EL ADMINITRADOR -->
      <Descripcion :asignacion="asignacion" />

      <!-- DATOS PEQUEÑOS -->
      <MiniDatos :asignacion="asignacion" />

      <!-- TABLA -->
      <div class="row justify-content-center mt-5 card p-3" v-if="isAdmin || isFinanzas">
        <div class="col-12 card-body">
          <h5 class="font-weight-bold" v-b-toggle:tabla-precios>Precios</h5>
          <b-collapse id="tabla-precios">
            <hr />
            <div class="table-responsive">
              <table class="table table-custom">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      <span class="small">Gasto</span>
                    </th>
                    <th scope="col">
                      <span class="small">Monto</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="asignacion.totalPrecioContratista">
                    <td data-label="Gasto">Pago Total Contatista</td>
                    <td data-label="Monto">
                      {{ formatoAPrecio(asignacion.totalPrecioContratista) }}
                    </td>
                  </tr>
                  <tr v-if="asignacion.AdelantoContratista && asignacion.AdelantoContratista.estado == 1">
                    <td data-label="Gasto">Adelanto</td>
                    <td data-label="Monto">
                      {{ formatoAPrecio(asignacion.AdelantoContratista.monto) }}
                    </td>
                  </tr>
                  <tr v-if="asignacion.bono">
                    <td data-label="Gasto">Bono</td>
                    <td data-label="Monto">
                      {{ formatoAPrecio(asignacion.bono) }}
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Gasto"><b>Monto Restante</b></td>
                    <td data-label="Monto">
                      <b>{{ formatoAPrecio(resultado()) }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-collapse>
        </div>
      </div>

      <!-- ADELANTO Y BONO -->
      <Dinero @open-bono="openCrearBono(asignacion)" :asignacion="asignacion" />

      <!-- CARD CON DETALLES DEL NEGOCIO LOCAL -->
      <Local :asignacion="asignacion" />

      <hr class="mt-5" />

      <!-- FOTOS -->
      <Fotos :asignacion="asignacion" />

      <hr />

      <!-- ARCHIVOS SUBIDOS -->
      <div class="row justify-content-center mt-5 card p-3">
        <div class="col-12 card-body">
          <h5 class="font-weight-bold" v-b-toggle:documentos>DOCUMENTOS</h5>
          <b-collapse id="documentos">
            <hr />
            <!-- FOTO OT -->
            <div class="card" v-if="asignacion.documentoOT">
              <div class="card-body">
                <p class="font-weight-bold" v-b-toggle:documento-firmado>
                  DOCUMENTO FIRMADO
                </p>
                <b-collapse id="documento-firmado">
                  <hr />
                  <p class="pointer h5">
                    >
                    <span @click="openDocumento"
                      ><code>(Ver Documento en otra pestaña)</code></span
                    >
                  </p>
                  <div class="d-flex justify-content-center">
                    <img
                      style="max-width: 100%; max-height: 300px; margin: auto;"
                      :src="asignacion.documentoOT"
                      alt=""
                    />
                  </div>
                </b-collapse>
              </div>
            </div>
            <!-- VOUCHER DE PAGO -->
            <div
              class="card mt-3"
              v-if="
                asignacion.voucher && (isAdmin || isFinanzas || isContratista)
              "
            >
              <div class="card-body">
                <p class="font-weight-bold" v-b-toggle:voucher-pago>
                  VOUCHER DE PAGO
                </p>
                <b-collapse id="voucher-pago">
                  <hr />
                  <p class="pointer h5">
                    >
                    <span @click="openVoucher"><code>(Ver Voucher)</code></span>
                  </p>
                  <p class=" h5">
                    > Número de Operación:
                    <span
                      ><code>{{ asignacion.nroOperacion }}</code></span
                    >
                  </p>
                  <p class=" h5">
                    > Fecha de Pago:
                    <span
                      ><code>{{ asignacion.fechaPago }}</code></span
                    >
                  </p>
                </b-collapse>
              </div>
            </div>
            <!-- ORDEN DE COMPRA -->
            <div
              class="card mt-3"
              v-if="asignacion.ordenCompra && (isAdmin || isFinanzas)"
            >
              <div class="card-body">
                <p class="font-weight-bold pointer">
                  ORDEN DE COMPRA
                  <span @click="openOrden"><code>(Ver Orden)</code></span>
                </p>
              </div>
            </div>
            <!-- FACTURA -->
            <div class="card mt-3" v-if="asignacion.documentoFactura">
              <div class="card-body">
                <p class="font-weight-bold pointer">
                  FACTURA
                  <span @click="openFactura"><code>(Ver Factura)</code></span>
                </p>
              </div>
            </div>
          </b-collapse>
        </div>
        <!--  -->
      </div>

      <hr class="mt-5" />

      <!-- PERSONAL ASIGNADO -->
      <Personal v-if="isAdmin" :asignacion="asignacion" />

      <!-- TAREAS -->
      <div
        class="row justify-content-center mt-5 card p-3"
        no-body
        v-if="
          tareas &&
            tareas.length > 0 &&
            (isAdmin || isSupervisor || isContratista)
        "
      >
        <div class="col-12 card-body">
          <h5 class="font-weight-bold" v-b-toggle:partidas>PARTIDAS</h5>
          <b-collapse id="partidas">
            <hr />
            <b-tabs card content-class="mt-3" fill>
              <b-tab title="Partidas - Linea de Tiempo" active>
                <TimelineTareas :tareas="tareas" />
              </b-tab>
              <b-tab title="Partidas - Listado">
                <ListadoTareas :tareas="tareas" />
              </b-tab>
            </b-tabs>

            <div class="p-5">
              <b-media v-if="isAdmin || isFinanzas">
                <template #aside>
                  <b-avatar variant="success" size="4em"
                    ><i class="fas fa-user-tie"></i
                  ></b-avatar>
                </template>

                <h5 class="mt-0">Precio Total Administrador</h5>
                <p class="font-weight-bold">
                  {{ formatoAPrecio(asignacion.totalPrecioAdministrador) }}
                </p>
              </b-media>
              <b-media
                class="mt-4"
                v-if="isAdmin || isFinanzas || isContratista"
              >
                <template #aside>
                  <b-avatar variant="warning" size="4em"
                    ><i class="fas fa-user-cog"></i
                  ></b-avatar>
                </template>

                <h5 class="mt-0">Precio Total Contratista</h5>
                <p class="font-weight-bold">
                  {{ formatoAPrecio(asignacion.totalPrecioContratista) }}
                </p>
              </b-media>
            </div>
          </b-collapse>
        </div>
      </div>

      <div
        class="row card mt-5"
        v-if="tareas.length <= 0 && (isAdmin || isSupervisor || isContratista)"
      >
        <div class="card-body">
          <p class="font-weight-bold">Aún no se han ingresado tareas</p>
        </div>
      </div>
    </section>

    <!-- MODAL PARA CREAR BONO -->
    <modal-crear-bono
      v-if="asignationSelected"
      :active="crearBono"
      :item="asignationSelected"
      @on-modal="crearBono = $event"
      @on-finished="getInfo"
    ></modal-crear-bono>
  </LayoutView>
</template>

<script>
import LayoutView from "@/layouts/detalles-asig.vue";
import { estados } from "../../../../.env";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";
import GetTareas from "@/apollo/queries/tareas/GetAllTareas.gql";
import { mapState } from "vuex";
import { precio } from "@/mixins/precio";

// COMPONENTES
import ImagenNegocio from "@/components/detalleAsignacion/ImagenNegocio.vue";
import Descripcion from "@/components/detalleAsignacion/Descripcion.vue";
import MiniDatos from "@/components/detalleAsignacion/Minidatos.vue";
import Dinero from "@/components/detalleAsignacion/Dinero.vue";
import Local from "@/components/detalleAsignacion/Local.vue";
import Fotos from "@/components/detalleAsignacion/Fotos.vue";
import Personal from "@/components/detalleAsignacion/Personal.vue";

// TAREAS
import TimelineTareas from "@/components/detalleAsignacion/tareas/timeline.vue";
import ListadoTareas from "@/components/detalleAsignacion/tareas/listado.vue";

import ModalCrearBono from "@/components/asignaciones/modalCrearBono.vue";

export default {
  components: {
    LayoutView,
    ImagenNegocio,
    TimelineTareas,
    ListadoTareas,
    ModalCrearBono,
    Descripcion,
    MiniDatos,
    Dinero,
    Local,
    Fotos,
    Personal,
  },
  mixins: [precio],
  data() {
    return {
      id: this.$route.params.id,
      estados: estados,
      asignacion: null,
      tareas: [],
      crearBono: false,
      asignationSelected: null,
    };
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    isAdmin() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    isSupervisor() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    isContratista() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 3;
    },
    isFinanzas() {
      return parseInt(this.$store.state.datosUsuarioLogueado.tipoUsuario) === 4;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    resultado() {
      const contratista = Number(this.asignacion.totalPrecioContratista);
      const bono = this.asignacion.bono ? Number(this.asignacion.bono) : 0;
      const adelantoCon = this.asignacion.AdelantoContratista && this.asignacion.AdelantoContratista.estado == 1
        ? Number(this.asignacion.AdelantoContratista.monto)
        : 0;

      return contratista - adelantoCon + bono;
    },
    openDocumento() {
      const ruta = this.asignacion.documentoOT;
      window.open(ruta, "_blank").focus();
    },
    openVoucher() {
      const ruta = this.asignacion.voucher;
      window.open(ruta, "_blank").focus();
    },
    openOrden() {
      const ruta = this.asignacion.ordenCompra;
      window.open(ruta, "_blank").focus();
    },
    openFactura() {
      const ruta = this.asignacion.documentoFactura;
      window.open(ruta, "_blank").focus();
    },
    openCrearBono(item) {
      this.asignationSelected = { ...item };
      this.crearBono = true;
    },
    init() {
      this.getInfo();
    },

    getTareas() {
      this.$apollo
        .query({
          query: GetTareas,
          variables: {
            numberPaginate: 1000,
            page: 1,
            codigoTicket: this.asignacion.codigoTicket,
            estado: "",
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.errors) {
          }
          this.tareas = res.data.GetAllTareas.data;
        });
    },

    getInfo() {
      this.$apollo
        .query({
          query: GetAsignaciones,
          variables: {
            codigoTicket: this.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.asignacion = response.data.GetAsignaciones;

          this.getTareas();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.foto {
  width: 100%;
}

.modal-img {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.7);
}
</style>
