<template>
  <div class="row mt-3 mt-md-5 align-items-stretch">
    <!-- DESCRIPCION DEL LOCAL -->
    <div class="col-md-6">
      <div class="card">
        <!-- <img :src="local" class="card-header img-fluid" alt="" /> -->
        <div class="card-body">
          <p v-b-toggle:local-collapse class="h6 font-weight-bold pb-1">
            Datos del local
          </p>
        <b-collapse id="local-collapse">
          <hr>
            <p class="h6 card-subtitle my-3 text-muted">
            {{ asignacion.local.nomLocal }}
          </p>
          <hr />
          <p class="h6 card-text mt-3 mb-2">
            <i class="fas fa-map-marker-alt"></i>
            {{ asignacion.direccionLocal }} -
            {{ asignacion.local.departamento }} -
            {{ asignacion.local.provincia }} -
            {{ asignacion.local.distrito }}
          </p>

          <hr />

          <p
            class="h6 card-text mt-3 mb-2"
            v-if="asignacion.latitud && asignacion.longitud"
          >
            <i class="fas fa-map-marker-alt"></i>
            Latitud: {{ asignacion.latitud }} - Longitud:
            {{ asignacion.longitud }}
          </p>

          <hr />

          <div class="row no-gutters">
            <div class="col-12 col-md-6">
              <span class="card-link h6">
                <i class="fas fa-mobile-alt mr-2"></i>
                {{ asignacion.local.celular }}
              </span>
            </div>

            <div class="col-12 col-md-6 mt-2 mt-md-0">
              <a
                :href="`mailto:${asignacion.local.emailContacto}`"
                class="card-link h6"
              >
                <span class="text-dark">
                  <i class="far fa-envelope mr-2"></i>
                </span>

                {{ asignacion.local.emailContacto }}
              </a>
            </div>
          </div>
          <hr />
        </b-collapse>
        </div>
      </div>
    </div>

    <!-- INFORMACIÓN DE VISITA DEL SUPERVISOR -->
    <div class="col-md-6 mt-3 mt-md-0">
      <div class="card">
        <!-- <img :src="supervisor" class="card-header img-fluid" alt="" /> -->

        <div class="card-body">
          <template v-if="visitaSupervisor">
            <p v-b-toggle:datos-supervisor class="h6 pb-1 font-weight-bold ">
              Datos llenados por supervisor
            </p>

           <b-collapse id="datos-supervisor">
             <hr>
              <p class="card-text mt-3 mb-0 border-bottom">
              <span class="text-muted">Local reconocido:</span>
              <span class="small d-inline-block ml-2">{{
                asignacion.fechaInicioOriginal
              }}</span>
            </p>

            <p class="card-text mt-3 mb-0 border-bottom">
              <span class="text-muted">Personal de atención:</span>
              <span class="small d-inline-block ml-2">{{
                asignacion.atencion
              }}</span>
            </p>

            <p class="card-text mt-3 mb-0 border-bottom">
              <span class="text-muted">Nro. técnicos:</span>
              {{ asignacion.numerodeTecnicos }}
            </p>

            <div class="mt-3 border-bottom">
              <span class="text-muted">Observaciones:</span>
              <p class="my-0 small">
                {{ asignacion.observaciones }}
              </p>
            </div>

            <div class="mt-3">
              <span class="text-muted">Comentarios adicionales:</span>
              <p class="my-0 small">
                {{ asignacion.comentariosAdicionales }}
              </p>
            </div>
           </b-collapse>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { asignacion: { type: Object, required: true } },
  data() {
    return {
      // COLLAPSIBLE
      local: require("@/assets/extra/local.jpg"),
      supervisor: require("@/assets/extra/personal-02.jpg"),
    };
  },
  computed: {
    visitaSupervisor() {
      let status = false;

      let { latitud, longitud } = this.asignacion;

      if (latitud && longitud) {
        status = true;
      }

      return status;
    },
  },
};
</script>
